import React, { useState, useEffect, Fragment } from "react";
// import BlogContent from "./ShowcaseContent";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../../firebase";

const ShowcaseList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "showcase"));
      const d = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        d.push(Object.assign({ id }, doc.data()));
      });
      setData(d);
    };
    fetchData();
  }, []);
  // const PostList = BlogContent.slice(0, 6);
  return (
    <Fragment>
      <div className="row">
        {data.map((doc) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={doc.id}>
              <div className="blog blog-style--1">
                <div className="thumbnail">
                  <a href={`/showcase-details/${doc.id}`}>
                    <img className="w-100" src={doc.image} alt={doc.title} />
                  </a>
                </div>
                <div className="content">
                  <p className="blogtype">{doc.customer}</p>
                  <h4 className="title">
                    <a href={`/showcase-details/${doc.id}`}>{doc.title}</a>
                  </h4>
                  <div className="blog-btn">
                    <a
                      className="rn-btn text-white"
                      href={`/showcase-details/${doc.id}`}
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};
export default ShowcaseList;
