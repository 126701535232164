import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../../../firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. You will hear from someone momentarily.
    </p>
  );
};
const Error = () => {
  return (
    <p className="error-message">
      We're having issues connecting right now, please try again later.
    </p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);
  const [error, showError] = useState(false);
  const responseBody = {};
  const contactUs = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.forEach((value, property) => (responseBody[property] = value));
    console.log(JSON.stringify(responseBody));

    try {
      const docRef = await addDoc(collection(db, "contact"), {
        fullname: responseBody.fullname,
        email: responseBody.email,
        phone: responseBody.phone,
        subject: responseBody.subject,
        message: responseBody.message,
      });

      console.log("Document written with ID: ", docRef.id);
      e.target.reset();
      showresult(true);
    } catch (e) {
      console.log(e);
      const analytics = getAnalytics();
      logEvent(analytics, "error_contact");
      showError(true);
    }
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form action="" onSubmit={contactUs}>
      <div className="rn-form-group">{result ? <Result /> : null}</div>
      <div className="rn-form-group">{error ? <Error /> : null}</div>
      <div className="rn-form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Your Email" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Phone Number" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder="Your Message" required></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>
    </form>
  );
}
export default ContactForm;
