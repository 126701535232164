// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import PicaseeHome from "./home/PicaseeHome";

// Element Layout
// import Service from "./elements/Service";
// import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
// import PortfolioDetails from "./elements/PortfolioDetails";
import Showcase from "./elements/Showcase";
import ShowcaseDetail from "./elements/ShowcaseDetail";
import error404 from "./elements/error404";

// Blocks Layout
import Counters from "./blocks/Counters";
// import ShowCase from "./blocks/ShowCase";
// import Gallery from "./blocks/Gallery";
// import Commercial from "./blocks/Commercial";
import ContactForm from "./blocks/ContactForm";
// import Columns from "./blocks/Columns";
import Packages from "./blocks/Packages";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={PicaseeHome}
            />
            {/* Element Layot */}
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={Service}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service-details`}
              component={ServiceDetails}
            /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/showcase`}
              component={Showcase}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/showcase-details/:id`}
              component={ShowcaseDetail}
            />

            {/* Blocks Elements  */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/counters`}
              component={Counters}
            />
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/showcase`}
              component={ShowCase}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/gallery`}
              component={Gallery}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/commercial`}
              component={Commercial}
            /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={ContactForm}
            />
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/columns`}
              component={Columns}
            /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/packages`}
              component={Packages}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
