import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || picasee </title>
                    <meta name="description" content="Picasee is a custom graphic company that will sketchify a memory of yours, bringing it to life with an acompanying video and optional story. Perfect for personal gifts, memories, as well as corporate opportunities." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
