import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
// import Pagination from "./common/Pagination";
import ShowcaseList from "./showcase/ShowcaseList";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Blog extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="showcase" />
        <Header />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Showcase"} />
        {/* End Breadcrump Area */}

        {/* Start Showcase List Area */}
        <div className="rn-blog-area ptb--120 bg_color--1">
          <div className="container">
            <ShowcaseList />
          </div>
        </div>
        {/* End Showcase List Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Blog;
