import React, { useState, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
// import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
// import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
// import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
// import BrandTwo from "../elements/BrandTwo";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import ModalVideo from "react-modal-video";
import { videoTagString, VideoTag } from "react-video-tag";
import Helmet from "../component/common/Helmet";
// import { TextLoop } from "react-text-loop-next";

videoTagString({
  src: "https://firebasestorage.googleapis.com/v0/b/picasee-store.appspot.com/o/public%2Fdemo.mp4?alt=media&token=4d211339-0b3f-458c-a08a-7b066836f43a",
  poster: "/assets/images/bg/bg-image-24.jpg",
});

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "Welcome Video Studio",
    description: "",
    buttonText: "View Packages",
    buttonLink: "/packages",
  },
];

const PicaseeHome = () => {
  const [matches] = useState(window.matchMedia("(min-width: 768px)").matches);

  return (
    <Fragment>
      <Helmet pageTitle="home" />
      {/* Start Header Area  */}
      <Header />
      {/* End Header Area  */}

      {/* Start Slider Area   */}
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
            key={index}
            data-black-overlay={matches ? "8" : "6"}
          >
            <div className="container ml--5">
              <div className="row">
                {/* <div className="col-lg-8">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                        </div>
                                    </div> */}
                <div className="col-lg-4 mt_md--5 mt_sm--5">
                  {/* <h1 className="title ml--0 text-center">
                      <TextLoop
                        springConfig={{ stiffness: 60, damping: 15 }}
                        className="themecolor"
                      >
                        <span className="" style={{ color: "#df5022" }}>
                          Life
                        </span>
                        <span style={{ color: "#10e498" }}>Love</span>
                        <span style={{ color: "#145ee2" }}>Sketchified</span>
                      </TextLoop>
                    </h1> */}
                  <div
                    className={`inner ${value.textPosition} mb_md--50 mb_sm--50`}
                  >
                    {/* {value.category ? <span>{value.category}</span> : ""}

                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn ml--10">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="video-background">
              <VideoTag
                autoPlay={`${true}`}
                muted={`${true}`}
                playsInline={`${true}`}
                loop={`${true}`}
                src={`${"https://firebasestorage.googleapis.com/v0/b/picasee-store.appspot.com/o/public%2Fdemo.mp4?alt=media&token=4d211339-0b3f-458c-a08a-7b066836f43a"}`}
                poster={`${"/assets/images/bg/bg-image-24.jpg"}`}
              />
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      {/* <div className="about-area ptb--120">
          <AboutTwo />
        </div> */}
      {/* End About Area */}

      {/* Start Service Area  */}
      {/* <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div> */}
      {/* End Service Area  */}

      {/* Start Portfolio Area */}
      {/* <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
            <Portfolio />
          </div>
        </div> */}
      {/* End Portfolio Area */}

      {/* Start CounterUp Area */}
      <div className="portfolio-area ptb--120 bg_color--1">
        <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
      </div>
      {/* <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div> */}
      {/* End CounterUp Area */}

      {/* Start Testimonial Area */}
      <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End Testimonial Area */}

      {/* Start Blog Area */}
      {/* <div className="rn-blog-area pt--120 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6">
                <div className="section-title text-left">
                  <h2>Latest News</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                  <a className="btn-transparent rn-btn-dark" href="/blog">
                    <span className="text">View All News</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--30">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      {/* End Blog Area */}

      {/* Start Brand Area */}
      {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
      {/* End Brand Area */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );
};
export default PicaseeHome;
