import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore/lite";

const ShowcaseDetail = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showcase, setShowcase] = React.useState(undefined);
  const [documentError, setDocumentError] = React.useState(false);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      // const documentSnapshot = await getDoc(collection(db, "showcase"));

      const docRef = doc(db, "showcase", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setShowcase(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        setDocumentError(true);
      }
    };
    fetchData();
  }, [id]);

  return documentError ? (
    <p className="error-message">
      We're having issues connecting right now, please try again later.
    </p>
  ) : showcase ? (
    <React.Fragment>
      <PageHelmet pageTitle="showcase details" />
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">{showcase.title}</h2>
                <ul className="blog-meta d-flex justify-content-center align-items-center">
                  <li>
                    <FiClock />
                    {showcase.postDate}
                  </li>
                  <li>
                    <FiUser />
                    {showcase.customer}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <blockquote className="rn-blog-quote">
                    {showcase.quote}
                  </blockquote>

                  <div className="blog-single-list-wrapper d-flex flex-wrap">
                    <div className="thumbnail">
                      <img className="w-100" src={showcase.image} alt="Story" />
                      <span>{showcase.imageDetail}</span>
                    </div>
                    <div className="content">
                      <p>{showcase.content}</p>
                      <div className="thumb position-relative">
                        <img
                          className="w-100"
                          src="/assets/images/bg/bg-image-35.png"
                          alt="Service Images"
                        />
                        <ModalVideo
                          channel="youtube"
                          isOpen={isOpen}
                          videoId={showcase.youtubeId}
                          onClose={() => setIsOpen(false)}
                        />
                        <button
                          className="video-popup position-top-center"
                          onClick={() => setIsOpen(true)}
                        >
                          {/* <span className="play-icon"></span> */}
                          <img
                            className="w-100"
                            src="/assets/images/logo/picasee.png"
                            alt="Service Images"
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div className="video-wrapper position-relative mb--40">
                    <div className="thumbnail">
                      <img
                        src="/assets/images/bg/bg-image-11.jpg"
                        alt="Blog Images"
                      />
                    </div>
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId={showcase.youtubeId}
                      onClose={() => setIsOpen(false)}
                    />
                    <button
                      className="video-popup position-top-center"
                      onClick={() => setIsOpen(true)}
                    >
                      <span className="play-icon"></span>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  ) : (
    <p className="success-message">Loading showcase.</p>
  );
};
export default ShowcaseDetail;
